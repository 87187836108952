import Link from "next/link";
import ImageBox from "./ImageBox";

const RecruitmentCalculatorPreview = ({t, lang}) => {
  return (
    <>
      {/* <!-- About Section --> */}
      <section className="about-section -type-2 layout-pt-60 layout-pb-60">
        <div className="auto-container">
          <div className="row justify-content-between align-items-center">
            <div className="content-column mb-0 col-xl-5 col-lg-6 col-md-12 col-sm-12">
              <div data-aos="fade-right">
                <div className="sec-title">
                  <h2 className="sec-title-no-header">
                    {t('calculator.title')}
                  </h2>
                  <div className="text mt-30">
                    {t('calculator.subtitle')}
                  </div>
                </div>
                <Link
                  href={`/${lang}/recruitment-cost-calculator`}
                  className="theme-btn bg-blue mt-30"
                >
                  {t('calculator.button')}
                </Link>
              </div>
            </div>
            {/* <!-- Content Column --> */}

            <div className="image-group image-column -no-margin col-xl-6 col-lg-6 col-md-12 col-sm-12 wow fadeInRight">
              <ImageBox t={t} lang={lang} />
            </div>
            {/* End .image-column */}
          </div>
        </div>
      </section>
      {/* <!-- End About Section -->  */}
    </>
  );
};

export default RecruitmentCalculatorPreview;
