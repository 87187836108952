import useTranslation from 'next-translate/useTranslation';

const HowItWorks = () => {
  const { t } = useTranslation('home');
  const blockContent = [
    {
      id: 1,
      icon: "icon-drawing",
      title: t('how.block.1.title'),
      text: t('how.block.1.text'),
    },
    {
      id: 2,
      icon: "icon-process",
      title: t('how.block.2.title'),
      text: t('how.block.2.text'),
    },
    {
      id: 3,
      icon: "icon-task",
      title: t('how.block.3.title'),
      text: t('how.block.3.text'),
    },
    {
      id: 4,
      icon: "icon-one-finger-click",
      title: t('how.block.4.title'),
      text: t('how.block.4.text'),
    },
  ];
  return (
    <div className='block-8'>
      <div className='row block-8-inner'>
        {blockContent.map((item) => (
          <div className="col-lg-3 col-md-6 col-sm-12" key={item.id}>
            <div className="work-block -type-4">
              <div className="icon-wrap">
                <span className={`icon ${item.icon}`}></span>
              </div>

              <h5 className="title">{item.title}</h5>
              <p className="text">{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
