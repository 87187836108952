import useTranslation from "next-translate/useTranslation";

const Offer = () => {
  const { t } = useTranslation("home");
  const offers = t('offer', {}, { returnObjects: true });

  const renderBlock = (item) => (
    <div className="col-lg-6" key={item.title}>
      <div className="icon-side -type-1">
        <div className="content">
          <h4 className="title">{item.title}</h4>
          <p className="text">{item.text}</p>
        </div>
      </div>
    </div>
  );

  const renderSection = (block, index) => (
    <div className="about-section-two style-two ">
      <div className="auto-container">
        <div className={`row grid-base justify-content-between align-items-center ${index % 2 === 0 ? 'offer-section' : 'offer-section-odd'}`}>
          <div className="content-column mb-0 col-xl-5 col-lg-6 col-md-12 col-sm-12">
            <div className="wow fadeInLeft">
              <div className="sec-title mb-0">
                <h2>
                  {block?.title}
                </h2>
                <div className="text mt-36 md:mt-16">
                  {block?.subtitle}
                </div>
              </div>
              {/* <a href="#" className="theme-btn -blue-outline mt-56 md:mt-16">
                All Services
              </a> */}
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="row icon-side-row wow fadeInRight">
              {block?.items?.map((item) => renderBlock(item))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return offers?.block?.map((item, index) => renderSection(item, index));
};

export default Offer;
