import FormContent from "./FormContent";
import { useRouter } from 'next/router';

const InquiryPopup = () => {
  const { asPath } = useRouter();
  return (
    <>
      <div className="modal fade" id="inquiryPopupModal">
        <div className="modal-dialog modal-lg modal-dialog-centered login-modal modal-dialog-scrollable">
          <div className="modal-content">
            <button
              type="button"
              className="closed-modal"
              data-bs-dismiss="modal"
            ></button>
            {/* End close modal btn */}

            <div className="modal-body">
              {/* <!-- Login modal --> */}
              <div id="login-modal">
                {/* <!-- Login Form --> */}
                <div className="login-form default-form">
                  <FormContent source={`${asPath}__header`} page_url={asPath}/>
                </div>
                {/* <!--End Login Form --> */}
              </div>
              {/* <!-- End Login Module --> */}
            </div>
            {/* En modal-body */}
          </div>
          {/* End modal-content */}
        </div>
      </div>
      {/* <!-- Inquiry Popup Modal --> */}

    </>
  );
};

export default InquiryPopup;
