const ImageBox = ({t, lang}) => {
  return (
    <div className="image-box -type-1">
      <figure className="main-image" data-aos="fade-in" data-aos-delay="500">
        <img src={`images/resource/preview_calculator_${lang}.png`} alt="recruitment-cost-calculator-preview" />
      </figure>

      <div className="info_block_three" data-aos="fade-in" data-aos-delay="700">
        <span className="icon flaticon-money"></span>
        <p>
          {t('calculator.estimation.title_part_1')}<br/>
          {t('calculator.estimation.title_part_2')}
        </p>
      </div>
      {/* <!-- Info BLock One --> */}

      <div className="info_block_two" data-aos="fade-in" data-aos-delay="900">
        <p>{t('calculator.simplicity.title')}</p>
        {/* <div className="image">
          <img src="images/resource/multi-peoples.png" alt="" />
        </div> */}
      </div>
      {/* <!-- Info BLock Two --> */}

      {/* <div className="info_block_four" data-aos="fade-in" data-aos-delay="1100">
        <span className="icon flaticon-file"></span>
        <div className="inner">
          <p>Upload Your CV</p>
          <span className="sub-text">It only takes a few seconds</span>
        </div>
      </div> */}
      {/* <!-- Info BLock Four --> */}
    </div>
  );
};

export default ImageBox;
