import { InlineWidget } from "react-calendly";
import { PopupButton } from "react-calendly";
import useTranslation from 'next-translate/useTranslation';

const StepsBlock = () => {
  const { t } = useTranslation('home');
  return (
    <section id="free-resume-consultation" className="steps-section steps-section-calendly layout-pt-60 layout-pb-0">
      <div className="auto-container">
        <div className="row">
          <div className="image-column col-lg-6 col-md-12 col-sm-12">
            <div className="inner-column">
              {/* <figure className="image">
                <img src="images/index-11/text/image-1.png" alt="about" />
              </figure> */}
              <InlineWidget url="https://calendly.com/wojciech-b4xv" />
            </div>
          </div>
          {/* End .image-column */}

          <div className="content-column col-lg-6 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="sec-title -type-2">
                <h2>{t('leadMagnet.calendly.title')}</h2>
                {slotsLeft !== null && <div className="left-slots-section">{`${t('leadMagnet.calendly.availability.only')} ${slotsLeft}`}</div>}
                <div className="text">{t('leadMagnet.calendly.text')}</div>
                <div className="text">{t('leadMagnet.calendly.howTo')}</div>
                <ul className="steps-list">
                  <li>
                    <span className="count">01</span>{t('leadMagnet.calendly.step1')}
                  </li>
                  <li>
                    <span className="count">02</span>{t('leadMagnet.calendly.step2')}
                  </li>
                  <li>
                    <span className="count">03</span>{t('leadMagnet.calendly.step3')}
                  </li>
                </ul>
                <div className="text">{t('leadMagnet.calendly.applyNow')}</div>
              </div>
            </div>
          </div>
          {/* End .content-column */}
        </div>
      </div>
    </section>
  );
};

export default StepsBlock;
