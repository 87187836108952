import useTranslation from "next-translate/useTranslation";

const CallToAction5 = () => {
  const { t } = useTranslation("home");

  return (
    <section
      className="call-to-action-two style-two"
      style={{ "maxHeight": "450px", backgroundImage: "url(images/shutterstock_377378932.jpeg)" }}
    >
      <div className="auto-container">
        <div className="sec-title light text-center">
          <h2>{t('cta.title')}</h2>
          <div className="text">{t('cta.subtitle')}</div>
        </div>

        <div className="btn-box">
          <a
            href="https://meetings-eu1.hubspot.com/cezar-reszel"
            target="_blank"
            rel="noopener noreferrer"
            className="theme-btn btn-style-five"
          >
            {t('cta.button')}
          </a>
        </div>
      </div>
    </section>
    //   <!-- End Call To Action -->
  );
};

export default CallToAction5;
