import Link from "next/link";

const CTA = ({t, title}) => {
  return (
    <section className="call-to-action-five" id="cta">
      <div className="auto-container">
        {/* <div className="outer-box"> */}
        {/* <div className="row grid-base justify-content-center"> */}
          {/* <div className="col-lg-6 col-md-10"> */}
            <div className="sec-title text-center light">
              <h3>{title ? title : t('common:button.ctaTitle')}</h3>
              <div className="text">{t('common:button.ctaSubtitle')}</div>
            </div>
            {/* End sec-title */}

            <div className="btn-box">
              <Link
                href="https://meetings-eu1.hubspot.com/cezar-reszel"
                target="_blank"
                rel="noopener noreferrer"
                className="theme-btn btn-style-seven btn-cta"
              >
                {t('common:button.onlineCall')}
              </Link>
            {/* </div> */}
          {/* </div> */}
        </div>
        {/* End outer-box */}
      </div>
    </section>
  );
};

export default CTA;
