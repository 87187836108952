import AboutUs from "../about/AboutUs";
import Offer from "../about/Offer";
import Experts from "../top-company/Experts";
import HowItWorks from "../block/HowItWorks";
import RecruitmentReportPreview from '../about/RecruitmentReportPreview';
import RecruitmentCalculatorPreview from "../about/recruitment-calculator";
import Testimonial from "../testimonial/Testimonial";
import WhyUs from "../block/WhyUs";
import FooterDefault from "../footer/common-footer";
import CallToAction5 from "../call-to-action/CallToAction5";
import Hero5 from "../hero/hero-5";
import Header from "./Header";
import InquiryPopup from "../common/form/inquiry/InquiryPopup";
import MobileMenu from "../header/MobileMenu";
import useTranslation from "next-translate/useTranslation";
import CTA from "../call-to-action/CTA";
import StepsBlock from "../block/StepsBlock";

const Index = () => {
  const { t, lang } = useTranslation("home");
  return (
    <>
      <InquiryPopup />
      {/* End Inquiry Popup Modal */}

      {/* <PhoneCallPopup /> */}

      {/* <NewsletterPopup /> */}

      <Header />
      {/* <!--End Main Header --> */}

      <MobileMenu />
      {/* End MobileMenu */}

      <Hero5 />
      {/* End Hero Section */}

      <section className="testimonial-section style-two">
        <div className="auto-container">
          {/* <!-- Sec Title --> */}
          <div className="sec-title text-center">
            <h3 className="sec-title-no-header">{t('hero_hook.title')}</h3>
            <div className="text">
              {t('hero_hook.subtitle')}
            </div>
          </div>
        </div>
      </section>

      <Offer />

      <CTA t={t} />

      <section id="testimonial" className="testimonial-section -type-custom layout-pt-60 layout-pb-60">
        <div className="container-fluid">
          <div className="sec-title text-center">
            <h3 className="sec-title-no-header">{t('testimonials:title')}</h3>
            <div className="text">
              {t('testimonials:subtitle')}
            </div>
          </div>
        </div>
        <div className="carousel-outer">
          {/* <!-- Testimonial Carousel --> */}
          <div className="testimonial-carousel -not center-item-active">
            <Testimonial />
          </div>
        </div>
      </section>

      <section className="testimonial-section style-two layout-pb-120">
        <div className="auto-container">
          <div className="sec-title text-center">
            <h3 className="sec-title-no-header">{t('why.title')}</h3>
            <div className="text">{t('why.subtitle')}</div>
          </div>

          <div className="row grid-base">
            <WhyUs />
          </div>
        </div>
      </section>

      <CTA t={t} />

      <section className="top-companies style-two">
        <div className="auto-container">
          <div className="row grid-base align-items-center">
            <div className="content-column col-lg-4 col-md-12 col-sm-12">
              <div className="sec-title">
                <h2>{t('experts.title')}</h2>
                <div className="text">{t('experts.subtitle')}</div>
              </div>

            </div>
            <div className="content-column col-lg-8 col-md-12 col-sm-12">
              <div className="carousel-outer">
                <div className="companies-carousel">
                  <Experts />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="layout-pt-120">
        <div className="auto-container">
          <div className="sec-title text-center">
            <h2 className="sec-title-no-header">{t('how.title')}</h2>
            <div className="text">{t('how.subtitle')}</div>
          </div>

          <div className="row grid-base pt-50">
            <HowItWorks />
          </div>
        </div>
      </section>

      <CTA t={t} />

      <RecruitmentReportPreview />
      <RecruitmentCalculatorPreview t={t} lang={lang} />

      <section className="testimonial-section style-two">
        <div className="auto-container">
          <div className="row grid-base">
            <AboutUs />
          </div>
        </div>
      </section>
      {/* <!-- End About Section --> */}

      <CallToAction5 />
      <FooterDefault footerStyle="alternate5"/>
    </>
  );
};

export default Index;
