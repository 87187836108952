import useTranslation from 'next-translate/useTranslation';
import { GiArchiveResearch, GiAchievement, GiMeshNetwork } from 'react-icons/gi';

const WhyUs = () => {
  const { t } = useTranslation('home');
  const blockContent = [
    {
      id: 1,
      serialNo: "1",
      icon: GiAchievement,
      title: t('why.block.1.title'),
      text: t('why.block.1.text'),
      summary: t('why.block.1.summary'),
    },
    {
      id: 2,
      serialNo: "2",
      icon: GiArchiveResearch,
      title: t('why.block.2.title'),
      text: t('why.block.2.text'),
      summary: t('why.block.2.summary'),
    },
    {
      id: 3,
      serialNo: "3",
      icon: GiMeshNetwork,
      title: t('why.block.3.title'),
      text: t('why.block.3.text'),
      summary: t('why.block.3.summary'),
    }
  ];

  return (
    <>
      {blockContent.map((item) => (
        <div className="col-xl-4 col-lg-4 col-md-6" key={item.id}>
          <div className="step-item text-center h-100">
            <div className="content">
              <div className="icon-wrap">
                {item?.icon && <item.icon size={36} />}
              </div>
              <h3 className="title">{item.title}</h3>
              <p className="text">{item.text}</p>
            </div>
            <p className="summary">{item.summary}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default WhyUs;
