import Link from "next/link";
import { useState } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

const RecruitmentReportPreview = () => {
  const { asPath } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { lang, t } = useTranslation('home');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const groupId = lang === 'pl' ? process.env.NEXT_PUBLIC_ML_GROUP_RECRUITMENT_REPORT_PL : process.env.NEXT_PUBLIC_ML_GROUP_RECRUITMENT_REPORT_EN;
    
    const endpoint = `${process.env.NEXT_PUBLIC_ML_URL}/subscribers`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_ML_TOKEN}`,
      },
      body: JSON.stringify({
        email: e.target.email.value,
        groups: [groupId],
        fields: {
          source: `${asPath}__recruitment_report_button`,
          page_url: asPath || '/',
        },
      }),
    };

    try {
      const response = await fetch(endpoint, options);
      const result = await response.json();
      setSubmitted(true)
    } catch (error) {
      console.error('error', error);
    }
    setIsLoading(false);

  };

  const emailReportForm = () => {
    return (
      <form onSubmit={handleSubmit} className="default-form">
        <div className="form-group">
          <input
            type="email"
            name="email"
            className="lead-magnet-form__input"
            placeholder={t('report.form.email')}
            maxLength={80}
            required
          />
        </div>
        <div className="form-group">
        {isLoading ? (
          <button type="submit" className="theme-btn bg-blue full-width" disabled={true}>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        ) : (
          <button type="submit" className="theme-btn bg-blue full-width" disabled={submitted}>
            {submitted ? t('report.form.sent') : t('report.form.button')}
          </button>
        )}
        </div>
        <div className="terms-wrapper">
          <p className="terms">{t('report.form.term.text')} <Link href="/privacy-policy" target="_blank" rel="noopener noreferrer">{t('report.form.term.link')}</Link></p>
        </div>
      </form>
    )
  };

  return (
    <>
      {/* <!-- About Section --> */}
      <section className="about-section-two style-two layout-pt-60 layout-pb-60">
        <div className="auto-container">
          <div className="row justify-content-between align-items-center">
            {/* <!-- Image Column --> */}
            <div className="image-column -no-margin col-xl-6 col-lg-6 col-md-12 col-sm-12 wow fadeInRight">
              <div className="image-box -type-1">
                <figure
                  className="main-image"
                  data-aos-delay="500"
                  data-aos="fade-in"
                >
                  <img src={`images/resource/recruitment_report_preview_${lang}.png`} alt="resource" />
                </figure>

                {/* <!-- Info BLock One --> */}
                <div
                  className="info_block"
                  data-wow-delay="800"
                  data-aos="fade-in"
                >
                  <span className="icon flaticon-confirm"></span>
                  <p>
                    {t('report.experience.title')}
                  </p>
                </div>

                {/* <!-- Info BLock Two --> */}
                <div
                  className="info_block_two"
                  data-aos-delay="1100"
                  data-aos="fade-in"
                >
                  <span className="icon flaticon-handshake"></span>
                  <p>{t('report.fit.title')}</p>
                </div>

                {/* <!-- Info BLock Four --> */}
                <div
                  className="info_block_four"
                  data-aos-delay="1300"
                  data-aos="fade-in"
                >
                  <span className="icon flaticon-file"></span>
                  <div className="inner">
                    <p>{t('report.download.title')}</p>
                    <span className="sub-text">
                      {t('report.download.subtitle')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* End img-column */}

            {/* <!-- Content Column --> */}
            <div className="content-column mb-0 col-xl-5 col-lg-6 col-md-12 col-sm-12">
              <div className="sec-title">
                <h2 className="sec-title-no-header">
                  {t('report.title')}
                </h2>
                <div className="text mt-30">
                  {t('report.subtitle')}
                </div>
              </div>
              {emailReportForm()}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Section -->  */}
    </>
  );
};

export default RecruitmentReportPreview;
