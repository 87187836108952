import useTranslation from "next-translate/useTranslation";
import ImageBox from "./ImageBox";

const Index = () => {
  const { t } = useTranslation("home");
  return (
    <section className="banner-section-five">
      <div className="auto-container">
        <div className="row">
          <div className="content-column col-lg-7 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="title-box">
                <h1>{t('hero.header_title')}</h1>
                <h2>
                  {t('hero.title_prefix')} <span className="colored">{t('hero.title_suffix')}</span><span style={{display: 'block'}}>{t('hero.title_2nd')}</span>
                </h2>
                <h3 className="text">
                  {t('hero.subtitle')}
                </h3>
              </div>
              <a
                href=""
                className="theme-btn btn-style-seven call-modal"
                data-bs-toggle="modal"
                data-bs-target="#inquiryPopupModal"
              >
                {t('button.offer')}
              </a>
            </div>
          </div>

          <div className="image-column col-lg-5 col-md-12">
            <ImageBox t={t} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
