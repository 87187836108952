import Slider from "react-slick";
import useTranslation from "next-translate/useTranslation";

const Experts = () => {
  const { t } = useTranslation("home");
  const experts = t('experts', {}, { returnObjects: true });

  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {experts?.block.map((item) => (
        <div className="company-block" key={item.title}>
          <div className="inner-box">
            <h4 className="name">{item.title}</h4>
            <div className="text">{item?.text}</div>
            <div className="keywords">
              {item?.keywords?.map((keyword) => (
                <div className="keyword" key={keyword}>{keyword}</div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Experts;
