import Link from "next/link";
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';

const FormContent = ({source, page_url}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [consentAccepted, setConsentAccepted] = useState(false);
  const [consentAcceptedDate, setConsentAcceptedDate] = useState(null);
  const { t, lang } = useTranslation('modal');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const endpoint = '/api/forms/contact';
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: event.target.name.value,
        email: event.target.email.value,
        phone: event.target.phone.value,
        subject: event.target.subject.value,
        consent_date: consentAcceptedDate,
        lang: lang,
        source: source,
        page_url: page_url || '/',
      }),
    };
    try {
      const response = await fetch(endpoint, options);
      
      if (response.ok) {
        const result = await response.json();
      } else {
        // Error message handling goes here...
        console.error('Email sending failed:', response);
      }
      setSubmitted(true)
    }
    catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="form-inner">
      <h3>{t('contact.title')}</h3>

      {/* <!--Login Form--> */}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">{t('contact.field.name')}</label>
          <input
            type="text"
            name="name"
            className="name"
            placeholder={t('contact.placeholder.name')}
            maxLength={80}
            required
          />
        </div>
        {/* name */}

        <div className="form-group">
          <label htmlFor="email">{t('contact.field.email')}</label>
          <input
            type="email"
            name="email"
            className="email"
            placeholder={t('contact.placeholder.email')}
            maxLength={80}
            required
          />
        </div>
        {/* email */}

        <div className="form-group">
          <label htmlFor="phone">{t('contact.field.phone')}</label>
          <input
            type="tel"
            name="phone"
            className="phone"
            placeholder={t('contact.placeholder.phone')}
            maxLength={15}
            required
          />
        </div>
        {/* phone */}

        <div className="form-group">
          <label htmlFor="subject">{t('contact.field.subject')}</label>
          <textarea
            name="subject"
            className="subject short"
            maxLength={255}
            rows={2}
            placeholder={t('contact.placeholder.subject')}
            required
          />
        </div>
        {/* subject */}

        <div className="form-group">
          <div className="field-outer">
            <div className="input-group checkboxes square">
              <input
                type="checkbox"
                name="form_consent"
                id="form_consent"
                defaultValue={false}
                onChange={(e) => {
                  setConsentAccepted(e.target.checked);
                  setConsentAcceptedDate(new Date().toISOString());
                }}
                required
              />
              <label htmlFor="form_consent" className="terms-wrapper">
                <p className="terms">{t('common:consents.form.content')} <Link href={`/${lang}/privacy-policy`} target="_blank" rel="noopener noreferrer">{t('common:consents.form.privacy_policy')}</Link></p>
              </label>
            </div>
          </div>
        </div>

        <div className="form-group">
          {isLoading ? (
            <button
              className="theme-btn btn-style-one btn-style-one-disabled"
              type="submit"
              id="submit"
              name="submit-form"
              disabled={true}
            >
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {t('contact.button.sending')}
            </button>
          ) : (
            <button
              className={`theme-btn ${!consentAccepted || submitted ? 'btn-style-three btn-style-three-disabled' : 'btn-style-one'}`}
              type="submit"
              id="submit"
              name="submit-form"
              disabled={!consentAccepted || submitted}
            >
              {submitted ? t('contact.button.sent') : t('contact.button.send')}
            </button>
          )}
        </div>
        {/* submit */}
      </form>
      {/* End form */}

    </div>
  );
};

export default FormContent;
