import Link from "next/link";
import Image from 'next/image'
import useTranslation from "next-translate/useTranslation";
import teamImage from '../../public/images/about_us_image.jpeg';

const AboutUs = () => {
  const { t } = useTranslation("home");
  const about = t('about', {}, { returnObjects: true });

  const renderBlock = (item) => (
    <div className="col-lg-6" key={item.title}>
      <div className="icon-side -type-2">
        <div className="content">
          <h4 className="title">{item.title}</h4>
          <p className="text">{item.text}</p>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="content-column col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div className="inner-column">
          <div className="sec-title text-center">
            <h2>{about.title}</h2>
            <div className="text">
              <p>{about.subtitle}</p>
            </div>
            <div className="layout-pt-60">
              <Link
                href="https://meetings-eu1.hubspot.com/cezar-reszel"
                target="_blank"
                rel="noopener noreferrer"
                className="theme-btn btn-style-seven call-modal"
              >
                {about.cta}
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Content Column --> */}
      <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
        <div className="inner-column">
          <div className="row">
            {about?.block?.map((item) => renderBlock(item))}
          </div>
        </div>
      </div>
      {/* End .content-column */}

      {/* <!-- Image Column --> */}
      <div className="image-column col-lg-6 col-md-12 col-sm-12 layout-pt-60">
        <figure className="image-box">
          <Image
            src={teamImage}
            alt={t('about.image_alt')}
          />
        </figure>
      </div>
      {/* End image-column */}
    </>
  );
};

export default AboutUs;
